var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"add-new-sperma-sidebar","centered":"","visible":_vm.isAddNewSpermaSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","size":"lg","backdrop":"","no-header":"","right":"","ok-title":_vm.$t('ok'),"title":_vm.$t('spermaAdd'),"ok-only":""},on:{"ok":function($event){return _vm.onSubmit(_vm.blankSpermaData)},"hidden":function($event){_vm.formValidation(_vm.resetblanksperma).resetForm},"change":function (val) { return _vm.changed(val); }}},[_c('validation-observer',{ref:"formValidation(\n    resetblanksperma\n  ).refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{ref:"refForm",staticClass:"p-2 modal-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('button',{ref:"submitButtonRef",staticClass:"d-none",attrs:{"type":"submit"}}),_c('validation-provider',{attrs:{"rules":"required","name":"spermaresmino"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('spermaresmino'),"label-for":"spermaresmino"}},[_c('b-form-input',{attrs:{"id":"spermaresmino","autofocus":"","state":_vm.formValidation(_vm.resetblanksperma).getValidationState(
                validationContext
              ),"trim":"","placeholder":""},model:{value:(_vm.blankSpermaData.spermaresmino),callback:function ($$v) {_vm.$set(_vm.blankSpermaData, "spermaresmino", $$v)},expression:"blankSpermaData.spermaresmino"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"stokmiktari"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('stokmiktari'),"label-for":"stokmiktari"}},[_c('b-form-input',{attrs:{"id":"stokmiktari","autofocus":"","state":_vm.formValidation(_vm.resetblanksperma).getValidationState(
                validationContext
              ),"trim":"","type":"number","placeholder":""},model:{value:(_vm.blankSpermaData.stokmiktari),callback:function ($$v) {_vm.$set(_vm.blankSpermaData, "stokmiktari", $$v)},expression:"blankSpermaData.stokmiktari"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"irkid"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('race'),"label-for":"type"}},[_c('v-select',{staticClass:"w-100",attrs:{"transition":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.irk,"state":_vm.formValidation(_vm.resetblanksperma).getValidationState(
                validationContext
              ),"reduce":function (val) { return val.value; }},model:{value:(_vm.blankSpermaData.irkid),callback:function ($$v) {_vm.$set(_vm.blankSpermaData, "irkid", $$v)},expression:"blankSpermaData.irkid"}})],1)]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }