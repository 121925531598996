<template>
  <b-modal
    id="add-new-sperma-sidebar"
    centered
    :visible="isAddNewSpermaSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    size="lg"
    backdrop
    no-header
    right
    :ok-title="$t('ok')"
    :title="$t('spermaAdd')"
    ok-only
    @ok="onSubmit(blankSpermaData)"
    @hidden="formValidation(resetblanksperma).resetForm"
    @change="(val) => changed(val)"
  >
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="formValidation(
      resetblanksperma
    ).refFormObserver"
    >
      <!-- Form -->

      <b-form
        ref="refForm"
        class="p-2 modal-form"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <button ref="submitButtonRef" type="submit" class="d-none" />
        <!-- spermaName -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="spermaresmino"
        >
          <b-form-group :label="$t('spermaresmino')" label-for="spermaresmino">
            <b-form-input
              id="spermaresmino"
              v-model="blankSpermaData.spermaresmino"
              autofocus
              :state="
                formValidation(resetblanksperma).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <!-- stokmiktari -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="stokmiktari"
        >
          <b-form-group :label="$t('stokmiktari')" label-for="stokmiktari">
            <b-form-input
              id="stokmiktari"
              v-model="blankSpermaData.stokmiktari"
              autofocus
              :state="
                formValidation(resetblanksperma).getValidationState(
                  validationContext
                )
              "
              trim
              type="number"
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          rules="required"
          name="irkid"
        >
          <b-form-group :label="$t('race')" label-for="type">
            <v-select
              v-model="blankSpermaData.irkid"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="irk"
              :state="
                formValidation(resetblanksperma).getValidationState(
                  validationContext
                )
              "
              class="w-100"
              :reduce="(val) => val.value"
            />
          </b-form-group>
        </validation-provider>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewSpermaSidebarActive",
    event: "update:is-add-new-sperma-sidebar-active",
  },
  props: {
    isAddNewSpermaSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      irk: [],
      blankSpermaData: {
        id: 0,
        spermaresmino: "",
        plantid: this.$store.state.app.selectedPlantId,
        irkid: 0,
        stokmiktari: 0,
      },
      formValidation: formValidation,
    };
  },
  async mounted() {
    await this.fetchBreeds();
  },
  methods: {
    async fetchBreeds() {
      var { data } = await this.$http.get("/Animals/GetBreeds");

      this.irk = data.map((x) => {
        return { label: x.name, value: x.id };
      });
    },
    async changed(val) {
      this.$emit("update:is-add-new-sperma-sidebar-active", val);
      this.resetblanksperma();
    },
    onSubmit(blankSpermaData) {
      store
        .dispatch("definitionsModule/addSperma", blankSpermaData)
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-add-new-sperma-sidebar-active", false);
        });
    },
    async resetblanksperma() {
      this.blankSpermaData = {
        id: 0,
        spermaresmino: "",
        plantid: this.$store.state.app.selectedPlantId,
        irkid: 0,
        stokmiktari: 0,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-sperma-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
