<template>
  <div><sperma-list /></div>
</template>

<script>
import SpermaList from "./components/SpermaList/SpermaList.vue";
export default {
  components: { SpermaList },
};
</script>

<style></style>
