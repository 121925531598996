<template>
  <b-modal
    id="edit-sperma-sidebar"
    centered
    :visible="isEditSpermaSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    size="lg"
    backdrop
    no-header
    right
    :title="$t('updateSperma')"
    :ok-title="$t('ok')"
    ok-only
    @ok="onSubmit(blankSpermaData)"
    @hidden="formValidation(resetblanksperma).resetForm"
    @change="(val) => changed(val)"
  >
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="formValidation(
      resetblanksperma
    ).refFormObserver"
    >
      <!-- Form -->

      <b-form
        ref="refForm"
        class="p-2 modal-form"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <button ref="submitButtonRef" type="submit" class="d-none" />
        <validation-provider
          #default="validationContext"
          rules="required"
          name="spermaresmino"
        >
          <b-form-group :label="$t('spermaresmino')" label-for="spermaresmino">
            <b-form-input
              id="espermaresmino"
              v-model="blankSpermaData.spermaresmino"
              autofocus
              :state="
                formValidation(resetblanksperma).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <!-- stokmiktari -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="stokmiktari"
        >
          <b-form-group
            :label="$t('currentstokmiktari')"
            label-for="stokmiktari"
          >
            <b-form-input
              id="estokmiktari"
              v-model="blankSpermaData.stokmiktari"
              autofocus
              :state="
                formValidation(resetblanksperma).getValidationState(
                  validationContext
                )
              "
              trim
              type="number"
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <!-- orjstokmiktari -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="orjstokmiktari"
        >
          <b-form-group :label="$t('orjstokmiktari')" label-for="stokmiktari">
            <b-form-input
              id="estokmiktari"
              v-model="blankSpermaData.orjstok"
              autofocus
              :state="
                formValidation(resetblanksperma).getValidationState(
                  validationContext
                )
              "
              trim
              type="number"
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          rules="required"
          name="spermarace"
        >
          <b-form-group :label="$t('race')" label-for="type">
            <v-select
              v-model="blankSpermaData.irkid"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="irk"
              class="w-100"
              :reduce="(val) => val.value"
              :state="
                formValidation(resetblanksperma).getValidationState(
                  validationContext
                )
              "
            />
          </b-form-group>
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditSpermaSidebarActive",
    event: "update:is-edit-sperma-sidebar-active",
  },
  props: {
    isEditSpermaSidebarActive: {
      type: Boolean,
      required: true,
    },
    sperma: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      irk: [],
      required,
      blankSpermaData: {
        ...this.sperma,
        plantid: this.$store.state.app.selectedPlantId,
      },
      imgStr: "",
      formValidation: formValidation,
      profileFile: null,
      picturechanged: false,
      refInputEl: null,
      previewEl: null,
      inputImageRenderer: null,
    };
  },
  async mounted() {
    await this.fetchBreeds();

    var { inputImageRenderer } = useInputImageRenderer(
      this.$refs.refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        this.picturechanged = true;
        this.$refs.previewEl.src = base64;
        this.imgStr = base64;
      }
    );
    this.inputImageRenderer = inputImageRenderer;
  },
  methods: {
    async fetchBreeds() {
      var { data } = await this.$http.get("/Animals/GetBreeds");

      this.irk = data.map((x) => {
        return { label: x.name, value: x.id };
      });
    },
    async changed(val) {
      this.imgStr = "";
      this.resetblanksperma();

      this.$emit("update:is-edit-sperma-sidebar-active", val);
    },
    onSubmit() {
      this.blankSpermaData.imgstr = this.imgStr;
      store
        .dispatch("definitionsModule/updateSperma", this.blankSpermaData)
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-edit-sperma-sidebar-active", false);
        });
    },
    async resetblanksperma() {
      this.blankSpermaData = {
        ...this.sperma,
        plantid: this.$store.state.app.selectedPlantId,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-sperma-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
